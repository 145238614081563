<template>
<div>
  <b-tabs>

    <b-tab :title="$t(`agent.list.${i18nKey}.tab_general`)">

      <b-col md="12" class="my-2">
        <custom-input
          v-if="variables"
          class="my-1"
          id="custom-input-r2"
          :possibleValues="variables"
          v-model="agent.register_2"
          advanced
        >
          <template #label>
            {{ $t(`agent.list.${i18nKey}.register_1.title`) }}
            <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
            <span class="float-right text-right mx-25 text-secondary">{{ $t(`agent.list.${i18nKey}.register_1.description`) }}</span>
          </template>
        </custom-input>
          <div v-else class="mb-2">
            <b-skeleton class="mb-25" width="25%" height="12px" />
            <b-skeleton class="mb-25" width="100%" height="37px" />
            <b-skeleton class="mb-25" width="25%" height="14px" />
          </div>
      </b-col>

      <b-col md="12" class="my-2">
        <custom-input
          v-if="variables"
          class="my-1"
          id="custom-input-rd"
          :possibleValues="variables"
          v-model="agent.register_destiny"
          advanced
        >
          <template #label>
            {{ $t(`agent.list.${i18nKey}.register_destiny.title`) }}
            <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
            <span class="float-right text-right mx-25 text-secondary">{{ $t(`agent.list.${i18nKey}.register_destiny.description`) }}</span>
          </template>
        </custom-input>
          <div v-else class="mb-2">
            <b-skeleton class="mb-25" width="25%" height="12px" />
            <b-skeleton class="mb-25" width="100%" height="37px" />
            <b-skeleton class="mb-25" width="25%" height="14px" />
          </div>
      </b-col>

    <!-- ICON =========================== -->
      <b-col md="12" class="d-flex justify-content-center mt-0">
        <div>
          <p class="p-0 m-0 text-secondary text-center"><small>{{ $t(`agent.list.${i18nKey}.register_1.title`).toLowerCase() }}</small></p>
          <b-icon icon="file-earmark-text" shift-v="-6" scale="2" class="mx-2 mt-0"></b-icon>
        </div>
        <div class="mt-1"> 
          <p class="text-success mt-1"><big><big><b>,</b></big></big></p>
        </div>
        <div>
          <p class="p-0 m-0 text-secondary text-center"><small>{{ $t(`agent.list.${i18nKey}.tab_layout`).toLowerCase() }}</small></p>
          <b-icon icon="file-ruled" shift-v="-6" scale="2" class="mx-2 mt-0"></b-icon>
        </div>
        <b-icon icon="arrow-right-short" shift-v="-10" scale="2" class="mx-1 mt-2" variant="success"></b-icon>
        <div>
          <p class="p-0 m-0 text-secondary text-center"><small>{{ $t('common.terms.result') }}</small></p>
          <b-icon icon="box" shift-v="-6" scale="2" class="mx-2 mt-0" variant="success"></b-icon>

        </div>
      </b-col>
    <!-- ================================== -->

    </b-tab>


    <b-tab :title="$t(`agent.list.${i18nKey}.tab_layout`)">

      <b-col v-if="variables">
        <key-value-items
          :defaultValue="defaultListValue"
          v-model="agent.register_1.value"
          ref="KeyValueItems"
          :possibleValues="variables"
          :addButtonText="$t(`agent.list.${i18nKey}.label_add_new`)"
        >
          <template #field_label_left><b>{{$t(`agent.list.${i18nKey}.label_name`)}}:</b></template>
          <template #field_label_right><b> {{$t(`agent.list.${i18nKey}.label_size`)}}:</b></template>
        </key-value-items>
      </b-col>
    </b-tab>
  </b-tabs>
</div>

</template>

<script>
import { BLink, BSkeleton, BFormCheckbox, BButton, BContainer, BCol, BRow, BSidebar, BIcon, BTabs, BTab, BIconstack } from "bootstrap-vue";
import { mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import DefaultAgent from "./../DefaultAgent"
import VariablesPanel from "@/views/pages/middleware/MiddlewareManagement/Components/VariablesPanel.vue";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";
import HelperTooltip from '@/layouts/components/HelperTooltip';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import KeyValueItems from '@/layouts/components/SimpleKeyValueList.vue'
import {
  makeToast
} from "@/layouts/components/Popups.js";

import AgentsMixin from '../AgentsMixin'

export default {
  mixins: [AgentsMixin],

  components: {
    BButton,
    BContainer,
    BCol,
    BRow,
    BSidebar,
    VariablesPanel,
    CustomInput,
    BFormCheckbox,
    BIcon,
    BSkeleton,
    HelperTooltip,
    BLink,
    VuePerfectScrollbar,
    BTab,
    BTabs,
    BIconstack,
    KeyValueItems,
  },
  props: {
    middlewareID: {
      type: Number,
      required: true,
    },
    agentID: {
      type: Number,
      default: undefined,
    },
    value: {
      type: NormalizedAgent,
      default: undefined
    },
    uuid: {
      type: String,
      default: uuidv4(),
    },
    width: {
      type: String,
      default: "40%"
    },
    agentBlockID: {
      type: [ Number, String ],
      default: 1,
    },
    variables:{
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      actionID: 34,
      i18nKey: 'file_read',
      defaultAgent: {
        id: null,
        enum_agent_action_id: this.actionID,
        agent_block_id: this.agentBlockID,
        register_1: {
          source: 7,
          value: ""
        },
        register_2: {
          source: 7,
          value: ""
        },
        register_destiny: {
          source: 10,
          value: ""
        },
        execution_order: 1,
        fatal_on_fail: true,
      },

      uuidMap: {},

      variant_map: null,

      isSaving: false,
      layoutArray: [],
      arrayList : [],
      defaultListValue: {'key': '', 'value': ''},
      
    };
  },
  computed: {
    agent: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    toSaveRegs() {
      return {
        register_1: true,
        register_2: true,
        register_destiny: true,
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["insertAgent", "updateAgent", "getAgentByID"]),
    init() {
      DefaultAgent.fetchAgentByID(this)
    },
    save() {
      this.agent.register_1.value = this.$refs.KeyValueItems.resolveList();
      // console.log("FILE READ SAVE: ",(this.agent.register_1.value))
      if (!this.agent.register_1.value ||(!JSON.parse(this.agent.register_1.value).length)){
        makeToast({
        title: this.$t("agent.toast.create.error.title"),
        text: this.$t(`agent.list.${this.i18nKey}.error_uninformed_layout`),
        variant: "danger",
        icon: "XIcon",
        });
        return
      }
      return DefaultAgent.save(this)
    },
    validate() {
      return DefaultAgent.validate(this);
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    toggleSidebar() {
      this.$root.$emit("bv::toggle::collapse", this.uuid);
    },
  },
};
</script>

<style lang="scss" scoped>

.sidebar-container{
  max-height: 97vh;
  overflow-y: auto !important;
}

.sidebar-fixed-header{
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.sidebar-content{
  position: relative !important;
  padding-bottom: 20px;
  margin-bottom: 70px !important;
}


</style>